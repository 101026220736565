import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getIcon } from './icon';

import './index.scss';

import logo from '../../images/sidebar/logo.png';
import zendesk from '../../images/sidebar/zendesk.png';
import zendeskWhite from '../../images/sidebar/zendesk-white.png';
import setting from '../../images/settings.png';

import {
  AdministratorRole,
  ProductManagerRole,
  FirmwareManagerRole,
  FirmwareReaderRole,
  LoginContext,
  loginContextHasRole } from '../../contexts/LoginContext';


const Sidebar = ()=>{

    const history = useHistory();
    const location = useLocation();
    const loginContext = useContext(LoginContext);

    const [itemActive, setItemActive] = useState('Search');

    const goTo = (path:string)=>{
        history.push(path);
    }

    const onZendeskClick = ()=>{
        window.open('https://gradientcomfort.zendesk.com/');
    }
    const menus = useMemo(() => [
        {
            item:'Admins',
            href: '/admins',
            display: loginContextHasRole(loginContext, AdministratorRole),
        },
        {
            item:'Search',
            href: '/search',
            display: true,
        },
        {
            item:'NYCHA',
            href: '/nycha',
            display: true,
        },
        {
            item:'Device Groups',
            href: '/device-groups',
          display: loginContextHasRole(loginContext, ProductManagerRole) ||
            loginContextHasRole(loginContext, FirmwareManagerRole) ||
            loginContextHasRole(loginContext, FirmwareReaderRole),
        },
        {
          item: 'Firmware',
          href: '/firmware',
          display: loginContextHasRole(loginContext, FirmwareManagerRole) || loginContextHasRole(loginContext, FirmwareReaderRole),
        },
    // eslint-disable-next-line
    ], []);

    useEffect(()=>{
        menus.forEach( menu => {
            if(location.pathname.includes(menu.href)){
                setItemActive(menu.item);
            }
        })
    },[location.pathname, menus])
    return (
        <div className="sidebar">
            <div className="logo">
                <img src={logo} alt="" className="" />
                <p className="">Dashboard</p>
            </div>

            <div className="sidebar-menu">
                {menus.filter(menu => menu.display).map(menu =>{
                    return (
                        <div key={menu.href} onClick={()=>goTo(menu.href)} className={"sidebar-menu-item "+ (itemActive === menu.item ? 'sidebar-menu-item-active' : '')}>
                            {getIcon(menu.href, itemActive === menu.item)}
                            <p className="">{menu.item}</p>
                        </div>
                    )
                })}
                <div onClick={onZendeskClick} className={"sidebar-menu-item "+ (itemActive === 'Zendesk' ? 'sidebar-menu-item-active' : '')}>
                    <img src={itemActive === 'Zendesk' ? zendeskWhite : zendesk} alt="" className="zendesk" />
                    <p className="">Zendesk</p>
                </div>
            </div>

            <div className="control">
                <div onClick={()=>goTo('/settings')} className="control-item">
                    <img src={setting} alt="" className="zendesk" />
                </div>
            </div>

        </div>
    )
}
export default Sidebar;
