import React, { useEffect, useRef, useState } from 'react';

import Layout from '../../components/Layout'
import Loading from '../../components/Modal/Loading';
import DvTemprature from './components/dvtemprature';
import DvTargetTemps from './components/dvtargettemps';
import DvMacId from './components/dvmacid';
import DvMode from './components/dmmode';
import DvImage from './components/dvimg';

import treauApiClient, { TreauAPI } from '../../api_clients/TreauApiClient';

import Button from '../../components/Button';
import DataStreamingForm from './components/DataStreamingForm';
import { useToastAPIHandler } from '../../hooks/api';

import { useParams } from 'react-router';
import { AxiosResponse } from 'axios';

import './index.scss';

import searchIcon from '../../images/search.png';
import { Dropdown } from 'react-bootstrap';
import { validateEmail } from '../../utils/utils';
import { toast } from 'react-toastify';

import Dvlastconnected from "./components/dvlastconnected";
import Dvthingname from "./components/dvthingname";

import moment from 'moment';

const DashboardDetail = () => {

    // @ts-ignore
    const { deviceID } = useParams();
    const apiHandler = useToastAPIHandler();

    const [isLoading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("shadow");
    const [deviceDetail, setDeviceDetail] = useState<TreauAPI.ResponsesAdminDeviceDetail | null>(null);
    const [deviceShadowDoc, setDeviceShadowDoc] = useState<{ [key: string]: any }>({});
    const [deviceInfo, setDeviceInfo] = useState<{ [key: string]: any }>({});
    const [scheduleEvents, setScheduleEvents] = useState([] as any[]);
    const [shadowPingTime, setShadowPingTime] = useState<moment.Moment | null>(null);
    const [isPollingForShadow, setIsPollingForShadow] = useState<boolean>(false);
    const [deviceOffline, setDeviceOffline] = useState<boolean>(false);
    const [snMode, setSnMode] = useState<'indoor' | 'outdoor'>('indoor');
    const [shadowSearchValue, setShadowSearchValue] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [isEmailInvalid, setEmailInvalid] = useState(false);//TreauAPI.ResponsesAdminDeviceOverview[]
    const [userList, setUserList] = useState<TreauAPI.ResponsesDeviceUser[]>([])//useState<string[]>([]);
  //

    const shadowPollingRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const getDeviceDetail = () => {
        setLoading(true);
        apiHandler.handleRequest(treauApiClient.adminsAPI.adminGetDeviceDetails(deviceID), (response: AxiosResponse<TreauAPI.ResponsesAdminDeviceDetailResponse>) => {
            setDeviceDetail(response.data.data.device);
            setUserList(response.data.data.device.users);
            let shadowDocs: { [key: string]: any } = {}
            const shadowDoc = response.data.data.device.shadowDoc || {};
            const shadowDocDeviceInfo = shadowDoc.device_info || {};
            visitDescendants(shadowDoc, (key: string, value: any) => {
                shadowDocs[key] = value;
            });
            setDeviceShadowDoc(shadowDocs);

            let deviceInfo: { [key: string]: any } = {}
            visitDescendants(shadowDocDeviceInfo, (key: string, value: any) => {
                deviceInfo[key] = value;
            });
            setDeviceInfo(deviceInfo);

            setScheduleEvents(shadowDoc.schedule_event ?? []);

        }, undefined, () => setLoading(false));
    };

    const pingDevice = () => {
      setLoading(true);
      setShadowPingTime(moment());
      setIsPollingForShadow(true);
      apiHandler.handleRequest(
        treauApiClient.adminsAPI.adminPingDevice(deviceID),
        (response: AxiosResponse<TreauAPI.ResponsesAPIResponse>) => {
          setTimeout(getDeviceDetail, 1000);
        },
        () => setLoading(false)
      );
    }

    const onShadowSearchChange = (e: any) => {
        const VALUE = e.target.value as string;
        setShadowSearchValue(VALUE);
    }

    const onUserEmailChange = (e: any) => {
        const VALUE = e.target.value;
        if (isEmailInvalid) {
            setEmailInvalid(false);
        }
        setUserEmail(VALUE);
    }

    const onAddUserToDevice = () => {
        if (!validateEmail(userEmail)) {
            setEmailInvalid(true);
            return;
        }
        setLoading(true);
        apiHandler.handleRequest(
          treauApiClient.adminsAPI.adminAddUserToDevice(
            deviceID as string,
            { email: userEmail }
          ),
          (response: AxiosResponse<TreauAPI.ResponsesAdminAddUserToDeviceResponse>) => {
            if (response.data.data.result === 'added') {
                getDeviceDetail();
                toast.success('User will be automatically added.', { position: toast.POSITION.TOP_CENTER });
            } else if (response.data.data.result === 'invited') {
                toast.success('User will be email an invitation to device.', { position: toast.POSITION.TOP_CENTER });
            }


            setUserEmail('');
        }, undefined, () => setLoading(false));
    }

    const addUserToDeviceOnEnter = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        onAddUserToDevice();
      }
    }

    function visitDescendants(obj: any, callback: any) {
        for (const [key, value] of Object.entries(obj)) {
            if (value && (typeof value === "object" || Array.isArray(value))) {
            } else {
                callback(key, value);
            }
        }
    }

    const getShadowInfo = () => {
        if (deviceDetail && Object.keys(deviceShadowDoc).length > 0) {
            return <>
                <div className="item-section">Shadow Info</div>
                <div className="list-info">
                    {Object.keys(deviceShadowDoc).map(info => {
                        if (info.includes(shadowSearchValue)) {
                            return (
                                <div key={info} className="info-item">
                                    <div className="name">{info}</div>
                                    <div className="value">{deviceShadowDoc[info] !== undefined && deviceShadowDoc[info].toString()}</div>
                                </div>
                            )
                        }
                        return null;
                    })}
                </div>
            </>
        }
        return null;
    }

    const getDeviceInfo = () => {
        if (deviceDetail && Object.keys(deviceInfo).length > 0) {
            return <>
                <div className="item-section">Device Info</div>
                <div className="list-info">
                    {Object.keys(deviceInfo).map(info => {
                        if (info.includes(shadowSearchValue)) {
                            return (
                                <div key={info} className="info-item">
                                    <div className="name">{info}</div>
                                    <div className="value">{deviceInfo[info] !== undefined && deviceInfo[info].toString()}</div>
                                </div>
                            )
                        }
                        return null;
                    })}
                </div>
            </>
        }
        return null;
    }

    const getScheduleEvent = () => {
        if (deviceDetail && scheduleEvents.length > 0) {
            return <>
                <div className="item-section">Schedule Events</div>
                <div className="list-schedule-events">
                    {scheduleEvents.map((event: any, index: number) => {
                        return (<div key={index} className="child-list">
                            {Object.keys(event).map(childK => {
                                return (
                                    <div key={childK + index} className="child-item">
                                        <div className="child-key">{childK}</div>
                                        <div className="child-value">{event[childK]}</div>
                                    </div>
                                )
                            })
                            }
                        </div>)
                    })}
                </div>
            </>
        }
        return null;
    }

    const renderShadowPingButton = () => {
      if (deviceDetail && deviceDetail.deviceType === 'denali') {
        if (deviceOffline) {
          return <p className='mt-4'>Device appears to be offline</p>;
        } else if (isPollingForShadow && !isLoading) {
          return <div className="mt-4 text-center">
            <p>Polling for fresh shadow...</p>
            <p>This display will refresh every 5 seconds</p>
          </div>;
        } else if (!isLoading) {
          return <Button className='mt-4' onClick={pingDevice}>Ping for <br />Fresh Shadow</Button>;
        }
      }
    }

    const getAppsDisplay = (user: TreauAPI.ResponsesDeviceUser) => {
      if (user.apps.length === 0) {
        return (<>
          platform: <span style={{"fontWeight":"bold"}}>{user.appPlatform?user.appPlatform:"N/A"}</span> | version: <span style={{"fontWeight":"bold"}}>{user.appVersion?user.appVersion:"N/A"}</span>
        </>);
      } else if (user.apps.length === 1) {
        return (<>
          platform: <span style={{"fontWeight":"bold"}}>{user.apps[0].appPlatform}</span> | version: <span style={{"fontWeight":"bold"}}>{user.apps[0].appVersion}</span>
        </>);
      } else {
        return (<>
          <span style={{"fontWeight":"bold"}}>Apps:</span> {user.apps.map((app, index) => (
          <React.Fragment key={app.clientId}>
            { index > 0 && "," } platform: <span style={{"fontWeight":"bold"}}>{app.appPlatform}</span> | version: <span style={{"fontWeight":"bold"}}>{app.appVersion}</span>
          </React.Fragment>
          ))}
        </>);
      }
    }

    useEffect(() => {
      getDeviceDetail();
      return () => {
        if (shadowPollingRef.current) {
          clearTimeout(shadowPollingRef.current);
        }
      }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (isPollingForShadow) {
        if (moment(deviceDetail.lastConnectedTime).isAfter(shadowPingTime)) {
          setIsPollingForShadow(false);
        } else if (shadowPingTime.isBefore(moment().subtract(30, 'seconds'))) {
          setIsPollingForShadow(false);
          setDeviceOffline(true);
        } else {
          shadowPollingRef.current = setTimeout(getDeviceDetail, 5000);
        }
      }
    // eslint-disable-next-line
    }, [deviceDetail]);

    useEffect(() => {
      if (activeTab !== 'shadow' && shadowPollingRef.current) {
        clearTimeout(shadowPollingRef.current);
        setIsPollingForShadow(false);
      }
    }, [activeTab]);

    return (
        <div className="dashboard-detail">
            <Loading isLoading={isLoading} />
            <Layout>
                <div className="dashboard-detail-content">

                    {deviceDetail && <div className="device-information">

                        <div className="device-infor">
                            <div className="content">
                                <Dropdown className='dv-mode-option'>
                                    <Dropdown.Toggle className='dv-mode-option' variant="success" id="dropdown-basic">
                                        {snMode === 'indoor' ? 'Indoor Unit' : 'Outdoor Unit'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setSnMode('indoor')} >Indoor Unit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setSnMode('outdoor')}>Outdoor Unit</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <DvImage temp={deviceShadowDoc['target_temp_c']} />
                                <DvMode
                                  deviceType={deviceDetail.deviceType}
                                  state={deviceShadowDoc['state_system']}
                                  power={deviceShadowDoc['state_power'] || deviceShadowDoc['power_state']}
                                  mode={deviceShadowDoc['op_mode']}
                                  fanSpeed={deviceShadowDoc['power_level'] || deviceShadowDoc['fan_setting']}
                                  runStatus={deviceShadowDoc['run_status']}
                                />
                                <DvMacId macId={snMode === 'indoor' ? (deviceInfo["idb_sn"] || deviceInfo['idb_id'] || deviceInfo['idb_mac_id']) : (deviceInfo['odb_sn'] || deviceInfo['odb_id'] || deviceInfo['odb_mac_id'])} />
                                <Dvlastconnected lastConnected={deviceDetail.lastConnectedTime}/>
                                <Dvthingname thingName={deviceDetail.thingName}/>
                                <DvTemprature insideTemprature={deviceShadowDoc['temp_atei_c'] || deviceShadowDoc['indoor_temp_c']} outsideTemprature={deviceShadowDoc['temp_ateo_c']} />
                                {deviceShadowDoc['heat_target_temp_c'] !== undefined && <DvTargetTemps heatTargetTempC={deviceShadowDoc['heat_target_temp_c']} coolTargetTempC={deviceShadowDoc['cool_target_temp_c']} /> }

                            </div>
                            <div className="adding-user-to-device">
                                <div className="title">Add user to device</div>
                                <div className="form">
                                  <input type="text" value={userEmail} onChange={onUserEmailChange} onKeyPress={e => addUserToDeviceOnEnter(e)} className="" placeholder='User email' />
                                    <button onClick={onAddUserToDevice} className="submit-button">Submit</button>
                                </div>
                                {isEmailInvalid && <div className="valid-message">Email is invalid.</div>}
                                {userList && userList.length > 0 && <div className="invitations">
                                    <div className="title">User List:</div>
                                    <div className="invitation-list">
                                        {userList.map((user, index) => (
                                          <div key={user.name + index} className={"invitation-item " + (user.isPrimaryUser ? 'hightlight' : '')}>
                                            {user.name + ' - ' + user.email} | {getAppsDisplay(user)}
                                          </div>
                                        ))}
                                    </div>
                                </div>}
                            </div>
                        </div>

                        <div className="shadow-infor">
                            <div className="shadow-tab">
                              <div
                                className={`tab-item ${activeTab === "shadow" ? "tab-item-active" : ""}`}
                                onClick={() => setActiveTab("shadow")}
                              >Shadow Info</div>
                              { deviceDetail.deviceType === "denali" && <div
                                  className={`tab-item ${activeTab === "dataStreaming" ? "tab-item-active" : ""}`}
                                  onClick={() => setActiveTab("dataStreaming")}
                                >
                                  Data Streaming
                              </div>}
                            </div>
                            { activeTab === "shadow" && <>
                              <div className="search">
                                  <img src={searchIcon} alt="" />
                                  <input value={shadowSearchValue} onChange={onShadowSearchChange} type="text" />
                              </div>
                              {renderShadowPingButton()}
                              {getShadowInfo()}
                              {getDeviceInfo()}
                              {getScheduleEvent()}
                            </>}
                            { activeTab === "dataStreaming" && <DataStreamingForm unid={deviceID} /> }
                        </div>
                    </div>}
                </div>
            </Layout>
        </div>
    )
}

export default DashboardDetail;
