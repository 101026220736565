import React, { useContext, useEffect, useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../sass/common.scss';
import {
    Route,
    Switch,
    BrowserRouter as Router
} from "react-router-dom";
import {ToastContainer} from 'react-toastify';

import treauApiClient, { TreauAPI } from '../api_clients/TreauApiClient';
import { useToastAPIHandler } from '../hooks/api';
import { AxiosResponse } from 'axios';

import LoginPage from './LoginPage/loadable';
import SettingPage from './Settings/loadable';
import { ErrorContextProvider } from '../contexts/ErrorContext';
import { DownloadContextProvider } from '../contexts/DownloadContext';
import TokenExchangePage from './TokenExchangePage/loadble';
import {
  AdministratorRole,
  FirmwareManagerRole,
  FirmwareReaderRole,
  ProductManagerRole,
  LoginContext,
} from '../contexts/LoginContext';
import { login } from '../contexts/LoginContext/actions';
import { LocationContextProvider } from '../contexts/LocationContext';
import { EmailContextProvider } from '../contexts/EmailContext';
import SearchPage from './SearchPage';
import DeviceGroupCreatePage from './DeviceGroupCreatePage/loadable';
import DeviceGroupDetailPage from './DeviceGroupDetailPage/loadable';
import DeviceGroupListPage from './DeviceGroupListPage/loadable';
import AdminListPage from './AdminListPage/loadable';
import AdminCreatePage from './AdminCreatePage/loadable';
import AdminDetailPage from './AdminDetailPage/loadable';
import FirmwareListPage from './FirmwareListPage/loadable';
import FirmwareDetailPage from './FirmwareDetailPage/loadable';
import FirmwareCreatePage from './FirmwareCreatePage/loadable';
import NychaPage from './NychaPage';
import DashboardDetail from './DashboardDetail';
import { RoleRestrictedRoute, UserRestrictedRoute } from '../components/misc/RestrictedRoutes';

const App = () =>{
    const loginContext = useContext(LoginContext);
    const [isLoading, setLoading] = useState(true);
    const apiHandler = useToastAPIHandler();
    useEffect(
      ()=>{
        if (window.location.pathname === '/login') {
          setLoading(false);
        } else {
          localStorage.removeItem("__TREAU_TOKEN__"); // TODO: remove this after a period of time
          localStorage.removeItem("__TREAU_ROLES__"); // TODO: remove this after a period of time
          apiHandler.handleRequest(
            treauApiClient.adminsAPI.adminFetchSelf(),
            (response: AxiosResponse<TreauAPI.ResponsesAdminSingleAdminResponse>) => {
              login(
                response.data.data.admin.adminRoles.map(r => r.role),
                loginContext.dispatch,
              );
            },
            undefined,
            () => setLoading(false)
          );
        }
      // eslint-disable-next-line
      }, []);
    return (
        <>
            <ToastContainer />
            <ErrorContextProvider>
            <DownloadContextProvider>
            <LocationContextProvider>
            <EmailContextProvider>
                {!isLoading && <Router>
                    <Switch>
                        <Route exact path='/' component={LoginPage} />
                        <Route exact path='/login' component={TokenExchangePage} />
                        <UserRestrictedRoute exact path='/settings' component={SettingPage} />
                        <UserRestrictedRoute path='/dashboard/:deviceID' component={DashboardDetail} />
                        <UserRestrictedRoute exact path='/search' component={SearchPage} />
                        <RoleRestrictedRoute exact path='/admins' component={AdminListPage} roles={[AdministratorRole]} />
                        <RoleRestrictedRoute exact path='/admins/create' component={AdminCreatePage} roles={[AdministratorRole]} />
                        <RoleRestrictedRoute exact path='/admins/:adminUnid' component={AdminDetailPage} roles={[AdministratorRole]} />
                        <RoleRestrictedRoute exact path='/firmware' component={FirmwareListPage} roles={[FirmwareManagerRole, FirmwareReaderRole]} />
                        <RoleRestrictedRoute exact path='/firmware/create' component={FirmwareCreatePage} roles={[FirmwareManagerRole]} />
                        <RoleRestrictedRoute path='/firmware/:firmwareType/:firmwareVersion' component={FirmwareDetailPage} roles={[FirmwareManagerRole, FirmwareReaderRole]} />
                        <UserRestrictedRoute path='/nycha' component={NychaPage} />
                        <RoleRestrictedRoute path='/device-groups/create' component={DeviceGroupCreatePage} roles={[FirmwareManagerRole, ProductManagerRole]} />
                        <RoleRestrictedRoute path='/device-groups/:deviceGroupUnid' component={DeviceGroupDetailPage} roles={[FirmwareManagerRole, FirmwareReaderRole, ProductManagerRole]} />
                        <RoleRestrictedRoute path='/device-groups' component={DeviceGroupListPage} roles={[FirmwareManagerRole, FirmwareReaderRole, ProductManagerRole]} />
                    </Switch>
                </Router>}
            </EmailContextProvider>
            </LocationContextProvider>
            </DownloadContextProvider>
            </ErrorContextProvider>
        </>
    )
}

export default App;
